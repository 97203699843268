import React, { useCallback, useState } from 'react';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { updateTeam } from 'src/store/modules/form/actions';

import { Box, Button, Input, Stack, Text, useToast } from '@chakra-ui/react';
import { v4 as uuidV4 } from 'uuid';

import { FormButton } from '../FormComponents/FormButton';
import { FormLabel } from '../FormComponents/FormLabel';
import { FormOuterContainer } from '../FormComponents/FormOuterContainer';

type IGroup = {
  id: string;
  index: number;
  value: string;
};

export const TeamFormation = () => {
  const [group, setGroup] = useState<IGroup[]>([
    { id: uuidV4(), index: 0, value: '' },
  ]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const addPerson = () => {
    if (group.length > 2) return;

    const newGroup = [...group];
    setGroup([
      ...newGroup,
      { id: uuidV4(), index: newGroup.length, value: '' },
    ]);
  };

  const fireToast = (message: string) => {
    toast({
      title: message,
      position: 'bottom',
      isClosable: true,
      status: 'error',
    });
  };

  const handleOnChange = (
    value: string,
    groupSent: IGroup[],
    index: number,
  ) => {
    if (value.charAt(-1) === ' ') return;

    const newGroup = [...groupSent];
    newGroup[index].value = value;

    setGroup(newGroup);
  };

  const handleNext = useCallback(
    (groupFormation: IGroup[]) => {
      const validGroup = groupFormation.filter(
        member => member.value.length > 0,
      );

      if (validGroup.length === 0) {
        fireToast('Adicione pelo menos um integrante ao seu grupo');
        return;
      }

      validGroup.forEach((member, index) => {
        dispatch(updateTeam(member.value, index + 1));
      });

      navigate('/form/3');
    },
    [dispatch],
  );

  return (
    <FormOuterContainer>
      <Box mb={{ base: '16rem', lg: '12.5rem' }} mt="auto" id="box">
        <FormLabel>
          <Text
            as="h1"
            fontSize={{ md: '1.8rem', lg: '2rem' }}
            fontWeight="500"
            fontFamily="Roboto"
          >
            Qual o nome dos integrantes do seu grupo?
          </Text>
          {group.map(person => {
            return (
              <Input
                key={person.id}
                mt={{ base: 3, md: 6, lg: 8 }}
                onChange={e =>
                  handleOnChange(e.target.value, group, person.index)
                }
                fontSize="1.5rem"
                variant="flushed"
                className="team-member"
              />
            );
          })}
        </FormLabel>
        <Button
          variant="unstyled"
          w="100%"
          d={group.length > 2 ? 'none' : 'flex'}
          alignItems="center"
          flexWrap="wrap"
          justifyContent={{
            base: 'space-evenly',
            md: 'flex-start',
            lg: 'flex-start',
          }}
          h="auto"
          mt="2rem"
          p="0.5rem"
          fontWeight="800"
          letterSpacing="wide"
          onClick={addPerson}
          data-cy="add-team-member"
        >
          <AiOutlinePlusCircle d="inline-flex" fontSize="1.75rem" />
          <Text as="p" mx="0.75rem">
            ADICIONAR INTEGRANTE
            <br />
          </Text>
          <Text as="span" color="yellow.cta">
            (ATÉ 2 PESSOAS)
          </Text>
        </Button>

        <Stack
          direction={{ base: 'column', lg: 'row' }}
          maxW="100%"
          mt={{ base: '4rem', lg: '6.25rem' }}
          spacing="1.5rem"
        >
          <FormButton data-cy="return" onClick={() => navigate('/form/2')}>
            VOLTAR
          </FormButton>
          <FormButton
            data-cy="submit"
            primary
            onClick={() => handleNext(group)}
          >
            AVANÇAR
          </FormButton>
        </Stack>
      </Box>
    </FormOuterContainer>
  );
};
