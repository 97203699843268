import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CharacterSvg from 'src/assets/characters.svg';
import GaragemLogoSvg from 'src/assets/garagem-logo.svg';
import PmlabLogoSvg from 'src/assets/pmlab-logo.svg';
import { clearData } from 'src/store/modules/form/actions';

import { useMsal } from '@azure/msal-react';
import { Box, Flex, Image, SimpleGrid, Text, useToast } from '@chakra-ui/react';

import { FormButton } from '../FormComponents/FormButton';

export const ThanksForSubmit = () => {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const fireToast = (titleMessage: string, descriptionMessage: string = '') => {
    toast({
      title: titleMessage,
      description: descriptionMessage,
      position: 'bottom',
      isClosable: true,
      status: 'error',
    });
  };

  const handleSendNewIdea = async () => {
    await instance
      .logoutPopup()
      .then(() => {
        dispatch(clearData());
        navigate('/login');
      })
      .catch(() => {
        fireToast(
          'Erro',
          'É necessário fazer o logout para enviar uma nova ideia',
        );
      });
  };

  return (
    <Flex
      h="100vh"
      maxW="90%"
      m="auto"
      align={{ base: 'flex-end', lg: 'center' }}
      justify="center"
    >
      <SimpleGrid
        alignItems={{ base: 'stretch', lg: 'center' }}
        columns={{ base: 1, lg: 2 }}
        spacingY={{ base: 1, lg: 3 }}
        templateAreas={{
          base: '"garage-logo" "message" "pmlab-logo" "character"',
          lg: '"character message" "garage-logo pmlab-logo"',
        }}
      >
        <Image
          pos="relative"
          m="auto"
          left={{ base: '-1.25rem', lg: '40%' }}
          bottom={{ base: '-2.5rem', lg: 'inherit' }}
          w={{ base: '100vw', lg: '50vw' }}
          src={CharacterSvg}
          gridArea="character"
        />
        <Box
          pos="relative"
          left="0%"
          top="-16px"
          gridArea="message"
          m={{ base: 'auto', lg: 'inherit' }}
        >
          <Flex flexDir="column" m="3rem 0 0 auto" h="fit-content">
            <Text
              fontSize="1.75rem"
              fontWeight="bold"
              lineHeight="2rem"
              mb="0.75rem"
            >
              Recebemos sua ideia!
            </Text>
            <Text fontSize="1.3rem" lineHeight="2.125rem" as="p">
              Obrigado por seu interesse
              <br />
              em transformar a Pague
              <br /> Menos.{' '}
              <strong>
                Juntos seremos cada
                <br /> vez mais Ágeis e Gigantes.
              </strong>{' '}
            </Text>
            <FormButton mt="1.625rem" onClick={() => handleSendNewIdea()}>
              ENVIAR UMA NOVA IDEIA
            </FormButton>
          </Flex>
        </Box>
        <Box>
          <Image
            maxW="300px"
            mr={{ base: 'auto', lg: '1rem' }}
            ml="auto"
            src={GaragemLogoSvg}
            gridArea="garage-logo"
          />
        </Box>
        <Box>
          <Image
            pos={{ base: 'absolute', lg: 'inherit' }}
            ml={{ base: 'auto', lg: '1rem' }}
            right={{ base: '1.5rem' }}
            bottom="4rem"
            w={{ base: '10rem', lg: 'max-content' }}
            maxW="300px"
            src={PmlabLogoSvg}
            gridArea="pmlab-logo"
          />
        </Box>
      </SimpleGrid>
    </Flex>
  );
};
