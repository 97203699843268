import React from 'react';

import { Button, ButtonProps } from '@chakra-ui/react';

interface Props extends ButtonProps {
  children: string;
  primary?: boolean;
}

export const FormButton = ({ children, primary, ...rest }: Props) => {
  return (
    <Button
      colorScheme="green"
      backgroundColor={primary ? 'green.cta' : 'transparent'}
      _hover={
        primary ? { backgroundColor: 'green.600' } : { backgroundColor: 'none' }
      }
      border={primary ? 'none' : '1px solid white'}
      color="white"
      fontWeight="800"
      p="1.1rem"
      w={{ base: '100%', lg: '13rem' }}
      {...rest}
    >
      {children}
    </Button>
  );
};
