import React, { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { updateSolutionDetails } from 'src/store/modules/form/actions';
import { IFormState, IFormSubmission } from 'src/store/modules/form/types';

import { Stack, Text, Textarea, Box, useToast } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { FormButton } from '../FormComponents/FormButton';
import { FormLabel } from '../FormComponents/FormLabel';
import { FormOuterContainer } from '../FormComponents/FormOuterContainer';

type ISolutionDetails = {
  solution: string;
};

const schema = yup.object({
  solution: yup.string().trim().required(),
});

export const SolutionDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ISolutionDetails>({
    resolver: yupResolver(schema),
    reValidateMode: 'onSubmit',
  });

  const handleNext: SubmitHandler<ISolutionDetails> = useCallback(
    ({ solution }: ISolutionDetails) => {
      dispatch(updateSolutionDetails(solution));
      navigate('/form/6', { replace: true });
    },
    [dispatch],
  );

  const {
    workplace,
    cellPhone,
    members,
    areaToInnovative,
    identifyingProblem,
    solutionProblem,
  } = useSelector<IFormState, IFormSubmission>(state => state.form);

  const fireToast = (message: string) => {
    toast({
      title: message,
      position: 'bottom',
      isClosable: true,
      status: 'error',
    });
  };

  useEffect(() => {
    if (!workplace || !cellPhone) navigate('/form/1');
    if (members?.length === 0) navigate('/form/2');
    if (!areaToInnovative) navigate('/form/3');
    if (!identifyingProblem) navigate('/form/4');
  }, []);

  useEffect(() => {
    if (errors.solution) fireToast(errors.solution.message!);
  }, [errors.solution]);

  return (
    <FormOuterContainer>
      <Box as="form" mb={{ base: '16rem', lg: '12.5rem' }} mt="auto"
        style={{ position: "relative", top: "50%", transform: "translateY(15%)" }}>
        <FormLabel>
          <Text
            as="h1"
            fontSize='1.7rem'
            fontWeight="500"
            fontFamily="Roboto"
          >
            Nos conte sobre a sua ideia, <br />
            estamos ansiosos para conhece-la!
          </Text>
          <Textarea
            {...register('solution')}
            variant="flushed"
            height="10rem"
            size="large"
            defaultValue={solutionProblem || ''}
            borderColor={errors.solution ? 'red' : 'white'}
            data-cy="solution-details"
          />
        </FormLabel>

        <Stack
          direction={{ base: 'column', lg: 'row' }}
          maxW="100%"
          mt={{ base: '4rem', lg: '6.25rem' }}
          spacing="1.5rem"
        >
          <FormButton data-cy="return" onClick={() => navigate('/form/4')}>
            VOLTAR
          </FormButton>
          <FormButton
            data-cy="submit"
            primary
            onClick={handleSubmit(handleNext)}
          >
            AVANÇAR
          </FormButton>
        </Stack>
      </Box>
    </FormOuterContainer>
  );
};
