import filesize from 'filesize';
import { AnyAction, Reducer } from 'redux';

import { IFormSubmission } from './types';

const INITIAL_STATE = {
  cellPhone: undefined,
  members: [] as string[],
  registration: undefined,
  workplace: undefined,
  areaToInnovative: undefined,
  identifyingProblem: undefined,
  solutionProblem: undefined,
  fileUrl: undefined,
  loja: undefined,
  estado: undefined,
  cidade: undefined,
} as IFormSubmission;

export const form: Reducer<any> = (
  state: IFormSubmission = INITIAL_STATE,
  { type, payload } = {} as AnyAction,
) => {
  switch (type) {
    case 'UPDATE_NAME_PHONE_WORKPLACE': {
      const oldState = { ...state };
      const { name, phone, workplace, loja, estado, cidade } = payload;

      if (!oldState.members) {
        oldState.members = [];
      }

      oldState.members[0] = name;

      let phoneNumber = phone.replace('(', '');

      phoneNumber = phoneNumber.replace(')', '');

      phoneNumber = phoneNumber.replace(' ', '');

      phoneNumber = phoneNumber.replace('-', '');

      return {
        ...oldState,
        cellPhone: phoneNumber,
        workplace,
        loja,
        estado,
        cidade,
      };
    }
    case 'UPDATE_PROBLEM_DETAILS': {
      const oldState = { ...state };
      const { problemDetails } = payload;
      return {
        ...oldState,
        identifyingProblem: problemDetails,
      };
    }
    case 'UPDATE_SOLUTION_DETAILS': {
      const oldState = { ...state };
      const { solutionDetails } = payload;
      return {
        ...oldState,
        solutionProblem: solutionDetails,
      };
    }
    case 'UPDATE_FILE_TO_UPLOAD': {
      const oldState = { ...state };
      const { fileToUpload: document } = payload;

      const fileObj = {
        file: document,
        readableSize: filesize(document.size),
        progress: 0,
        uploaded: false,
        error: null,
        url: null,
      };

      return {
        ...oldState,
        fileToUpload: fileObj,
      };
    }
    /**
     * The 'Main Team Member' is the one who logged in to submit the idea.
     * Only his/her email address will be stored in the database
     */
    case 'UPDATE_MAIN_TEAM_MEMBER': {
      const oldState = { ...state };
      let { registration } = oldState;
      const { members: updatedMembers } = oldState;

      const { member, index } = payload;

      registration = member.email;
      updatedMembers![index] = member.name;

      return {
        ...oldState,
        registration,
        members: updatedMembers,
      };
    }
    case 'UPDATE_TEAM': {
      const oldState = { ...state };
      let { members: oldStateMembers } = oldState;

      const { member, index } = payload;

      if (!oldStateMembers) {
        oldStateMembers = [];
      }

      oldStateMembers[index] = member;

      const updatedMembers = oldStateMembers.filter(m => !!m);

      return {
        ...oldState,
        members: updatedMembers,
      };
    }
    case 'UPDATE_AREA_TO_TRANSFORM': {
      const oldState = { ...state };
      const { area } = payload;
      return {
        ...oldState,
        areaToInnovative: area,
      };
    }
    case 'CLEAR_DATA': {
      const oldState = { ...state };
      return {
        ...oldState,
        cellPhone: undefined,
        members: undefined,
        registration: undefined,
        workplace: undefined,
        areaToInnovative: undefined,
        identifyingProblem: undefined,
        solutionProblem: undefined,
        fileUrl: undefined,
      };
    }
    default: {
      return state;
    }
  }
};
