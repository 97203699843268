import React, { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { FormOuterContainer } from 'src/components/Form/FormComponents/FormOuterContainer';
import { updateProblemDetails } from 'src/store/modules/form/actions';
import { IFormState, IFormSubmission } from 'src/store/modules/form/types';

import { Box, Stack, Text, Textarea, useToast } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { FormButton } from '../FormComponents/FormButton';
import { FormLabel } from '../FormComponents/FormLabel';

type IProblemDetails = {
  problem: string;
};

const schema = yup.object({
  problem: yup.string().trim().required('Esse campo é obrigatório'),
});

export const ProblemDetails = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IProblemDetails>({
    resolver: yupResolver(schema),
    reValidateMode: 'onSubmit',
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const { workplace, cellPhone, members, areaToInnovative } = useSelector<
    IFormState,
    IFormSubmission
  >(state => state.form);

  const handleNext: SubmitHandler<IProblemDetails> = useCallback(
    ({ problem }: IProblemDetails) => {
      dispatch(updateProblemDetails(problem));
      navigate('/form/5', { replace: true });
    },
    [dispatch],
  );

  const fireToast = (message: string) => {
    toast({
      title: message,
      position: 'bottom',
      isClosable: true,
      status: 'error',
    });
  };

  useEffect(() => {
    if (!workplace || !cellPhone) navigate('/form/1');
    if (members?.length === 0) navigate('/form/2');
    if (!areaToInnovative) navigate('/form/3');
  }, []);

  useEffect(() => {
    if (errors.problem) fireToast(errors.problem.message!);
  }, [errors.problem]);

  return (
    <FormOuterContainer>
      <Box as="form" mb={{ base: '16rem', lg: '12.5rem' }} mt="auto"
        style={{ position: "relative", top: "50%", transform: "translateY(15%)" }}>
        <FormLabel>
          <Text
            as="h1"
            fontSize='1.7rem'
            fontWeight="500"
            fontFamily="Roboto"
          >
            Qual problema você identificou? Conte para nós.
          </Text>
          <Textarea
            {...register('problem')}
            variant="flushed"
            height="12rem"
            borderColor={errors.problem ? 'red' : 'white'}
            data-cy="problem-details-textarea"
          />
        </FormLabel>

        <Stack
          direction={{ base: 'column', lg: 'row' }}
          maxW="100%"
          mt={{ base: '4rem', lg: '6.25rem' }}
          spacing="1.5rem"
        >
          <FormButton data-cy="return" onClick={() => navigate('/form/3')}>
            VOLTAR
          </FormButton>
          <FormButton
            data-cy="submit"
            primary
            onClick={handleSubmit(handleNext)}
          >
            AVANÇAR
          </FormButton>
        </Stack>
      </Box>
    </FormOuterContainer>
  );
};
