import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { updateAreaToTransform } from 'src/store/modules/form/actions';
import { IFormState, IFormSubmission } from 'src/store/modules/form/types';
import { areasPagueMenos } from 'src/utils/areasPagueMenos';

import { Box, Container, Select, Stack, Text } from '@chakra-ui/react';

import { FormButton } from '../FormComponents/FormButton';
import { FormLabel } from '../FormComponents/FormLabel';
import { FormOuterContainer } from '../FormComponents/FormOuterContainer';

export const AreaForInnovation = () => {
  const [selectedArea, setSelectedArea] = useState('administrativo');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { areaToInnovative, workplace, cellPhone, members } = useSelector<
    IFormState,
    IFormSubmission
  >(state => state.form);

  useEffect(() => {
    if (!workplace || !cellPhone) navigate('/form/1');
    if (members?.length === 0) navigate('/form/2');
  }, []);

  const handleNext = useCallback(
    (area: string) => {
      dispatch(updateAreaToTransform(area));

      navigate('/form/4', { replace: true });
    },
    [dispatch],
  );

  return (
    <FormOuterContainer>
      <Box
        mb={{ base: '16rem', lg: '12.5rem' }}
        mt="auto"
        as="form"
        id="group-choice"
        style={{ position: "relative", top: "50%", transform: "translateY(15%)" }}
      >
      <FormLabel>
        <Text
          as="h1"
          fontSize='1.7rem'
          fontWeight="500"
          fontFamily="Roboto"
        >
          Sua ideia transforma qual área?
        </Text>
        <Select
          variant="flushed"
          size="lg"
          fontSize='1.7rem'
          iconSize="x-large"
          color="white"
          defaultValue={areaToInnovative || selectedArea}
          onChange={e => setSelectedArea(e.target.value)}
          data-cy="area-selector"
        >
          {areasPagueMenos.map(area => (
            <option
              data-cy={area}
              style={{ background: '#00407e' }}
              key={area}
              value={area}
            >
              {area}
            </option>
          ))}
        </Select>
      </FormLabel>

      <Stack
        direction={{ base: 'column', lg: 'row' }}
        maxW="100%"
        mt={{ base: '4rem', lg: '6.25rem' }}
        spacing="1.5rem"
      >
        <FormButton data-cy="return" onClick={() => navigate('/form/2')}>
          VOLTAR
        </FormButton>
        <FormButton
          data-cy="submit"
          primary
          onClick={() => handleNext(selectedArea)}
        >
          AVANÇAR
        </FormButton>
      </Stack>
    </Box>
    </FormOuterContainer >
  );
};
