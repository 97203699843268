import React, { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { FormOuterContainer } from 'src/components/Form/FormComponents/FormOuterContainer';
import { updateNamePhoneWorkplace } from 'src/store/modules/form/actions';
import { IFormState, IFormSubmission } from 'src/store/modules/form/types';
import { phoneRegEx } from 'src/utils/phoneRegex';
import { areasPagueMenos } from 'src/utils/areasPagueMenos';
import { estadosCidades } from 'src/utils/estadosCidades';

import {
  Container,
  FormLabel,
  Input,
  Text,
  useToast,
  VStack,
  Select
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object } from 'yup';

import { FormButton } from '../FormComponents/FormButton';

type IFormInputs = {
  name: string;
  phone: string;
  workplace: string;
  loja: string,
  estado: string,
  cidade: string,
};

const schema = object({
  name: string()
    .trim()
    .required('É obrigatório preencher o campo "nome"')
    .max(255, 'O campo "nome" deve ter no máximo 255 caracteres'),
  phone: string()
    .trim()
    .required('É obrigatório fornecer telefone para contato')
    .matches(phoneRegEx, 'Número de telefone inválido'),
  workplace: string()
    .trim()
    .required('É obrigatório fornecer seu local de trabalho')
    .max(255, 'O campo "local de trabalho" deve ter no máximo 255 caracteres'),
  loja: string(),
  estado: string().required(''),
  cidade: string().required(),
});

export const Workplace = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [selectedArea, setSelectedArea] = useState('administrativo');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    reValidateMode: 'onSubmit',
  });

  const dispatch = useDispatch();

  const handleNext: SubmitHandler<IFormInputs> = useCallback(
    ({ name, phone, workplace, loja, estado, cidade }: IFormInputs) => {
      dispatch(updateNamePhoneWorkplace(name, phone, workplace, loja, estado, cidade));
      navigate('/form/2', { replace: true });
    },
    [dispatch],
  );

  const { members, cellPhone, workplace, loja } = useSelector<
    IFormState,
    IFormSubmission
  >(state => state.form);

  const fireToast = (message: string) => {
    toast({
      title: message,
      position: 'bottom',
      isClosable: true,
      status: 'error',
    });
  };

  const buscaCidades = (e: string) => {
    (document.querySelector("#cidade") as HTMLSelectElement).innerHTML = '';
    var cidade_select = document.querySelector("#cidade");

    var num_estados = estadosCidades.estados.length;
    var j_index = -1;

    // aqui eu pego o index do Estado dentro do JSON
    for (var x = 0; x < num_estados; x++) {
      if (estadosCidades.estados[x].nome == e) {
        j_index = x;
      }
    }

    if (j_index != -1) {

      // aqui eu percorro todas as cidades e crio os OPTIONS
      estadosCidades.estados[j_index].cidades.forEach(function (cidade) {
        var cid_opts = document.createElement('option');
        cid_opts.setAttribute('value', cidade)
        cid_opts.style.background = "#00407e"
        cid_opts.innerHTML = cidade;
        cidade_select?.appendChild(cid_opts);
      });
    } else {
      (document.querySelector("#cidade") as HTMLSelectElement).innerHTML = '';
    }
  }

  useEffect(() => {
    if (errors.name) fireToast(errors.name.message!);
    if (errors.phone) fireToast(errors.phone.message!);
    if (errors.workplace) fireToast(errors.workplace.message!);
  }, [errors.name, errors.phone, errors.workplace]);

  return (
    <FormOuterContainer>
      <VStack
        as="form"
        onSubmit={handleSubmit(handleNext)}
        spacing={12}
        mt="auto"
        mb={{ base: '16rem', lg: '7.6rem' }}
      >
        <FormLabel d="flex" flexDir="column" width="100%" style={{marginTop: "35px"}}>
          <Text
            fontSize={{lg:"1.1rem"}}
            fontWeight="500"
            fontFamily="Roboto"
            data-cy="label-name"
          >
            Olá, qual seu nome?
          </Text>
          <Input
            {...register('name')}
            fontSize="1.1rem"
            variant="flushed"
            borderColor={errors.name ? 'red' : 'white'}
            defaultValue={members ? members[0] : ''}
            data-cy="input-name"
          />
        </FormLabel>

        <FormLabel d="flex" flexDir="column" width="100%" style={{marginTop: "35px"}}>
          <Text
            fontSize="1.1rem"
            fontWeight="500"
            fontFamily="Roboto"
            data-cy="label-phone-number"
          >
            Por favor, informe o seu WhatsApp:
          </Text>
          <Input
            as={InputMask}
            {...register('phone')}
            name="phone"
            mask="(99) 99999-9999"
            placeholder="85 99999-9999"
            borderColor={errors.phone ? 'red' : 'white'}
            fontSize="1.3rem"
            variant="flushed"
            defaultValue={cellPhone || ''}
            data-cy="input-phone-number"
          />
        </FormLabel>

        <FormLabel d="flex" flexDir="column" width="100%" style={{marginTop: "35px"}}>
          <Text
            fontSize="1.1rem"
            fontWeight="500"
            fontFamily="Roboto"
            data-cy="label-workplace"
          >
            Você trabalha em qual Área?
          </Text>
          <Select
            {...register('workplace')}
            variant="flushed"
            size="lg"
            fontSize="1.3rem"
            iconSize="x-large"
            color="white"
            defaultValue={workplace || selectedArea}
            onChange={e => setSelectedArea(e.target.value)}
            data-cy="area-selector"
          >
            {areasPagueMenos.map(area => (
              <option
                data-cy={area}
                style={{ background: '#00407e' }}
                key={area}
                value={area}
              >
                {area}
              </option>
            ))}
          </Select>
        </FormLabel>

        {selectedArea == "Loja física" ? <FormLabel d="flex" flexDir="column" width="100%" style={{marginTop: "35px"}}>
          <Text
            fontSize="1.1rem"
            fontWeight="500"
            fontFamily="Roboto"
            data-cy="label-phone-number"
          >
            Caso seja loja física, informar:
          </Text>
          <Input
            {...register('loja')}
            fontSize="1.3rem"
            variant="flushed"
            borderColor={errors.loja ? 'red' : 'white'}
            defaultValue={loja || ''}
            data-cy="input-loja"
          />
        </FormLabel> : <></>}

        <FormLabel d="flex" flexDir="column" width="100%" style={{marginTop: "35px"}}>
          <Text
            fontSize="1.1rem"
            fontWeight="500"
            fontFamily="Roboto"
            data-cy="label-estado"
          >
            Selecione seu Estado?
          </Text>
          <Select
            {...register('estado')}
            fontSize="1.3rem"
            variant="flushed" id="estado"
            onChange={(e) => buscaCidades(e.target.value)}>
            <option value="">Selecione o Estado</option>
            {estadosCidades.estados.map(estado => (
              <option style={{ background: '#00407e' }} value={estado.nome}>{estado.nome}</option>
            ))}
          </Select>
        </FormLabel>
        <FormLabel d="flex" flexDir="column" width="100%" style={{marginTop: "35px"}}>
          <Text
            fontSize="1.1rem"
            fontWeight="500"
            fontFamily="Roboto"
            data-cy="label-workplace"
          >
            Selecione sua cidade?
          </Text>
          <Select
            id="cidade"
            {...register('cidade')}
            fontSize="1.3rem"
            variant="flushed" >
          </Select>

        </FormLabel>
        <Container d="flex" maxW="100%" p="0" m="0">
          <FormButton data-cy="submit" type="submit" primary>
            AVANÇAR
          </FormButton>
        </Container>
      </VStack>
    </FormOuterContainer>
  );
};
