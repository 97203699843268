import React from 'react';

import CharacterSvg from 'src/assets/characters.svg';
import PmlabLogoSvg from 'src/assets/pmlab-logo.svg';

import { Box, Image } from '@chakra-ui/react';

export const FormFooter = () => {
  return (
    <Box as="footer">
      <Image
        src={CharacterSvg}
        pos="fixed"
        bottom={{ base: -16, lg: 0 }}
        left={0}
      />
      <Image
        src={PmlabLogoSvg}
        pos="fixed"
        zIndex={3}
        bottom={{ base: 14, lg: 24 }}
        right={{ base: 6, lg: 16 }}
        w={{ base: 160, md: 200, lg: 220 }}
      />
    </Box>
  );
};
