import React from 'react';

import { FormLabel as ChakraFormLabel, FormLabelProps } from '@chakra-ui/react';

interface Props extends FormLabelProps {
  children: React.ReactNode;
}

export const FormLabel = ({ children, ...rest }: Props) => {
  return (
    <ChakraFormLabel
      as="label"
      d="flex"
      flexDir="column"
      fontSize={22}
      {...rest}
    >
      {children}
    </ChakraFormLabel>
  );
};
