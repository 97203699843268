import bgDesktop from 'src/assets/bg-desk.svg';
import bgMobile from 'src/assets/bg-mobile.svg';

import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  colors: {
    green: {
      cta: '#27ba49',
    },
    red: {
      error: '#ff0000',
    },
    yellow: {
      cta: '#fff0bc',
    },
    pmenosBlue: {
      '500': '#00407e',
    },
  },
  styles: {
    global: {
      body: {
        bgImage: { base: bgMobile, lg: bgDesktop },
        bgRepeat: 'no-repeat',
        bgSize: 'cover',
        bgAttachment: 'fixed',
        bgPosition: 'center',
        color: '#fff',
        fontFamily: 'Open Sans',
      },
      a: {
        cursor: 'pointer',
      },
      button: {
        cursor: 'pointer',
        fontWeight: '800',
        fontFamily: 'Open Sans',
      },
    },
  },
});
