import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';

import { FormFooter } from './FormFooter';

export const FormOutlet = () => {
  return (
    <>
      <AuthenticatedTemplate>
        <FormFooter />
        <Outlet />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Navigate to="/login" />
      </UnauthenticatedTemplate>
    </>
  );
};
