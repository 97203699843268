export const areasPagueMenos = [
  'Administrativo',
  'Atendimento ao cliente',
  'CRM',
  'Categoria de Produtos',
  'Compras',
  'Controladoria',
  'Digital',
  'Diversidade',
  'E-Commerce',
  'Expansão',
  'Financeiro',
  'Gente e Cultura',
  'Jurídico',
  'Logística',
  'Loja física',
  'Marcas próprias',
  'Marketing',
  'Obras',
  'Operações',
  'Outros',
  'Planejamento comercial',
  'Prevenção de perdas',
  'Pricing',
  'SAC',
  'Segurança',
  'Serviços Farmacêuticos',
  'Serviços de RH',
  'Social',
  'Supply',
  'TI',
  'Televendas',
  'Transformação de soluções de TI',
];
