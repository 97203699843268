import { IUserData } from './types';

export const updateNamePhoneWorkplace = (
  name: string,
  phone: string,
  workplace: string,
  loja: string,
  estado:string,
  cidade:string,
) => {
  return {
    type: 'UPDATE_NAME_PHONE_WORKPLACE',
    payload: {
      name,
      phone,
      workplace,
      loja,
      estado,
      cidade
    },
  };
};

export const updateProblemDetails = (problemDetails: string) => {
  return {
    type: 'UPDATE_PROBLEM_DETAILS',
    payload: { problemDetails },
  };
};

export const updateSolutionDetails = (solutionDetails: string) => {
  return {
    type: 'UPDATE_SOLUTION_DETAILS',
    payload: { solutionDetails },
  };
};

export const updateFileToUpload = (fileToUpload: File) => {
  return {
    type: 'UPDATE_FILE_TO_UPLOAD',
    payload: { fileToUpload },
  };
};

/**
 * The 'Main Team Member' is the one who logged in to submit the idea.
 * Only his/her email address will be stored in the database
 */
export const updateMainTeamMember = (member: IUserData, index: number) => {
  return {
    type: 'UPDATE_MAIN_TEAM_MEMBER',
    payload: { member, index },
  };
};

export const updateTeam = (member: string, index: number) => {
  return {
    type: 'UPDATE_TEAM',
    payload: { member, index },
  };
};

export const updateAreaToTransform = (area: string) => {
  return {
    type: 'UPDATE_AREA_TO_TRANSFORM',
    payload: { area },
  };
};

export const clearData = () => {
  return {
    type: 'CLEAR_DATA',
    payload: {},
  };
};
