import React from 'react';

import { IReactChildComponent } from 'src/global/types/globalComponentTypes';

import { Container } from '@chakra-ui/react';

export const FormOuterContainer = ({ children }: IReactChildComponent) => {
  return (
    <Container
      h="100%"
      style={{paddingTop: '30px'}}
      maxW={{ base: '80%', lg: '60%' }}
      p="0"
      d="flex"
      flexDir="column"
      justifyContent="center"
      alignItems="left"
    >
      {children}
    </Container>
  );
};
