import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlinePaperClip } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { baseAwsApi } from 'src/services/baseAwsApi';
import { IFormState, IFormSubmission } from 'src/store/modules/form/types';

import {
  Box,
  Checkbox,
  Divider,
  Flex,
  Stack,
  Text,
  useToast,
  VisuallyHiddenInput,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { FormButton } from '../FormComponents/FormButton';
import { FormLabel } from '../FormComponents/FormLabel';
import { FormOuterContainer } from '../FormComponents/FormOuterContainer';

type IPageValidation = {
  terms: boolean;
};

const schema = yup.object({
  terms: yup
    .boolean()
    .required('Os termos de compartilhamento de dados devem ser aceitos')
    .oneOf([true], 'Os termos de compartilhamento de dados devem ser aceitos'),
});

export const UploadFile = () => {
  const [isUploading, setIsLoading] = useState(false);
  const [file, setFile] = useState({} as File);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IPageValidation>({
    resolver: yupResolver(schema),
    reValidateMode: 'onSubmit',
  });

  const fireToast = (titleMessage: string, descriptionMessage: string = '') => {
    toast({
      title: titleMessage,
      description: descriptionMessage,
      position: 'bottom',
      isClosable: true,
      status: 'error',
    });
  };

  const {
    workplace,
    cellPhone,
    members,
    areaToInnovative,
    identifyingProblem,
    solutionProblem,
    registration,
    loja,
    estado,
    cidade
  } = useSelector<IFormState, IFormSubmission>(state => state.form);

  const handleSetFile = useCallback(
    (fileToUpload: File) => {
      if (!fileToUpload) {
        setFile({} as File);
        return;
      }
      setFile(fileToUpload);
    },
    [dispatch],
  );

  useEffect(() => {
    if (errors.terms) fireToast(errors.terms.message!);
  }, [errors.terms]);

  const handleNext = async (thisFile: File) => {
    setIsLoading(true);

    const data = new FormData();

    data.append('file', thisFile);

    const userIdea = {
      members,
      registration,
      cellPhone,
      workplace,
      areaToInnovative,
      identifyingProblem,
      solutionProblem,
      fileURL: 'no file attached',
      loja,
      estado,
      cidade,
    };

    if (!!thisFile.size) {
      await baseAwsApi
        .post('/FileUpload', data)
        .then(async item => {
          userIdea.fileURL = item.data.url;
          await baseAwsApi
            .post('/InsertIdea', userIdea)
            .then(() => {
              navigate('/form/7', { replace: true });
            })
            .catch(() => {
              fireToast('Erro ao enviar ideia', 'Por favor, tente novamente');
            })
            .finally(() => setIsLoading(false));
        })
        .catch(() => {
          fireToast(
            'Erro ao enviar o arquivo',
            'Apenas aquivos com formatos .pdf, .ppt ou .pptx são permitidos',
          );
        })
        .finally(() => setIsLoading(false));
    } else {
      await baseAwsApi
        .post('/InsertIdea', userIdea)
        .then(() => {
          navigate('/form/7', { replace: true });
        })
        .catch(() => {
          fireToast('Erro ao enviar ideia', 'Por favor, tente novamente');
        })
        .finally(() => setIsLoading(false));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!workplace || !cellPhone) navigate('/form/1');
    if (members?.length === 0) navigate('/form/2');
    if (!areaToInnovative) navigate('/form/3');
    if (!identifyingProblem) navigate('/form/4');
    if (!solutionProblem) navigate('/form/5');
  }, []);

  return (
    <FormOuterContainer>
      <Box mb={{ base: '14rem', lg: '11rem' }} mt="auto" style={{ position: "relative", top: "50%", transform: "translateY(15%)" }}>
        <Text
          as="h1"
          fontSize='1.7rem'
          fontWeight="500"
          fontFamily="Roboto"
        >
          Tem alguma arquivo pra apresentar sua ideia?
        </Text>
        <FormLabel>
          <Flex
            cursor="pointer"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text
              as="ins"
              color="#B7B7B7"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              fontFamily="Roboto"
              fontSize={{ base: '0.75rem', md: '1rem', lg: '1.5rem' }}
              fontWeight="500"
            >
              {file.name
                ? file.name
                : 'Clique aqui para enviar em .pdf ou .ppt'}
            </Text>
            <AiOutlinePaperClip fontSize="2.2rem" />
          </Flex>
          <VisuallyHiddenInput
            name="file"
            type="file"
            accept=".pdf, .ppt, .pptx"
            multiple={false}
            onChange={e => handleSetFile(e!.target!.files![0]!)}
          />
          <Divider borderWidth="1px" color="#fff" type="solid" mt="1rem" />
        </FormLabel>

        <Flex alignItems="flex-start" mt={12}>
          <Checkbox
            {...register('terms')}
            id="terms"
            mt="10px"
            mr="1rem"
            size="lg"
            colorScheme="green"
            data-cy="terms-checkbox"
          />
          <FormLabel
            htmlFor="terms"
            fontSize='1.2rem'
            fontWeight="500"
            fontFamily="Roboto"
            d="flex"
            data-cy="terms-label"
          >
            Eu concordo que ao oferecer os dados acima requeridos para o
            processo, estarei permitindo a utilização e armazenamento para fins
            exclusivos do processo de avaliação da sua empresa. Veja nossa
            <Text
              cursor="pointer"
              textColor="GrayText"
              textDecor="underline"
              d="contents"
              ml="4px"
            >
              {' '}
              Política de Privacidade.
            </Text>
          </FormLabel>
        </Flex>

        <Stack
          direction={{ base: 'column', lg: 'row' }}
          maxW="100%"
          mt={{ base: '1rem', md: '0.75rem', lg: '2.8rem' }}
          spacing="1.5rem"
        >
          <FormButton data-cy="return" onClick={() => navigate('/form/5')}>
            VOLTAR
          </FormButton>
          <FormButton
            data-cy="submit"
            isLoading={isUploading}
            loadingText="Enviando ideia"
            primary
            onClick={handleSubmit(() => handleNext(file))}
          >
            AVANÇAR
          </FormButton>
        </Stack>
      </Box>
    </FormOuterContainer>
  );
};
