import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { FormOutlet } from './components/Form/FormComponents/FormOutlet';
import { AreaForInnovation } from './components/Form/FormPages/AreaForInnovation';
import { ProblemDetails } from './components/Form/FormPages/ProblemDetails';
import { SolutionDetails } from './components/Form/FormPages/SolutionDetails';
import { TeamDecision } from './components/Form/FormPages/TeamDecision';
import { TeamFormation } from './components/Form/FormPages/TeamFormation';
import { ThanksForSubmit } from './components/Form/FormPages/ThanksForSubmit';
import { UploadFile } from './components/Form/FormPages/UploadFile';
import { Workplace } from './components/Form/FormPages/Workplace';
import { Login } from './pages/Login';

export const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />

      <Route path="/form" element={<FormOutlet />}>
        <Route path="1" element={<Workplace />} />
        <Route path="2" element={<TeamDecision />} />
        <Route path="3" element={<AreaForInnovation />} />
        <Route path="4" element={<ProblemDetails />} />
        <Route path="5" element={<SolutionDetails />} />
        <Route path="6" element={<UploadFile />} />
        <Route path="group" element={<TeamFormation />} />
      </Route>
      <Route path="/form/7" element={<ThanksForSubmit />} />

      <Route path="/login" element={<Login />} />

      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};
