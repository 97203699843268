import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import garagemLogo from 'src/assets/garagem-logo.svg';
import giganteLogo from 'src/assets/gigante-logo.svg';
import pmlabLogo from 'src/assets/pmlab-logo.svg';
import { FormButton } from 'src/components/Form/FormComponents/FormButton';

import { useMsal } from '@azure/msal-react';
import { Image, Box, Stack, useToast } from '@chakra-ui/react';

export const Login = () => {
  const [waitingForLogin, setWaitingForLogin] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const { instance } = useMsal();

  const handleLogin = async () => {
    setWaitingForLogin(true);
    await instance
      .loginPopup({ scopes: ['User.Read'] })
      .then(response => {
        instance.setActiveAccount(response.account);
      })
      .then(() => navigate('/form/1', { replace: true }))
      .catch(() => {
        toast({
          title: 'Erro ao realizar login',
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      })
      .finally(() => {
        setWaitingForLogin(false);
      });
  };

  return (
    <>
      <Box
        d="flex"
        flexDir={{ base: 'column', lg: 'row' }}
        pos="absolute"
        top={{ base: '80%', lg: 12 }}
        right={{ base: 0, lg: 12 }}
        left={{ base: 0, lg: 'auto' }}
        alignItems="center"
      >
        <Stack spacing={6} direction={{ base: 'column', lg: 'row' }}>
          <Image w={44} src={pmlabLogo} />
          <Image w={44} src={giganteLogo} />
        </Stack>
      </Box>

      <Box
        w="100vw"
        h="100vh"
        d="flex"
        flexDir="column"
        justifyContent="center"
        alignItems="center"
      >
        <Image src={garagemLogo} w={{ base: 340, md: '60%', lg: 600 }} mb={6} />
        <FormButton
          isLoading={waitingForLogin}
          loadingText="Aguardando login"
          primary
          h={16}
          w={{ base: '80%', md: '40%', lg: 340 }}
          onClick={handleLogin}
          data-cy="login"
        >
          QUERO ENVIAR MINHA IDEIA
        </FormButton>
      </Box>
    </>
  );
};
