import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { updateMainTeamMember } from 'src/store/modules/form/actions';
import { IFormState, IFormSubmission } from 'src/store/modules/form/types';

import { useMsal } from '@azure/msal-react';
import {
  Box,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';

import { FormButton } from '../FormComponents/FormButton';
import { FormLabel } from '../FormComponents/FormLabel';
import { FormOuterContainer } from '../FormComponents/FormOuterContainer';

export const TeamDecision = () => {
  const [value, setValue] = useState('alone');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const toast = useToast();

  const { workplace, cellPhone } = useSelector<IFormState, IFormSubmission>(
    state => state.form,
  );

  const fireToast = (message: string) => {
    toast({
      title: message,
      position: 'bottom',
      isClosable: true,
      status: 'error',
    });
  };

  useEffect(() => {
    if (!workplace || !cellPhone) navigate('/form/1');
  }, []);

  const handleNext = useCallback(
    async (groupChoice: string) => {
      await instance
        .acquireTokenSilent({
          scopes: ['user.read'],
          account: accounts[0],
        })
        .then(authenticationResult => {
          dispatch(
            updateMainTeamMember(
              {
                name: authenticationResult.account?.name!,
                email: authenticationResult.account?.username!,
              },
              0,
            ),
          );
        })
        .then(() => {
          if (groupChoice === 'alone') navigate('/form/3', { replace: true });
          else navigate('/form/group', { replace: true });
        })
        .catch(() =>
          fireToast('ERRO :: POR FAVOR TENTE NOVAMENTE EM ALGUNS SEGUNDOS'),
        );
    },
    [dispatch],
  );

  return (
    <FormOuterContainer >
      <Box
        mb={{ base: '16rem', lg: '12.5rem' }}
        mt="auto"
        as="form"
        id="group-choice"
        style={{position: "relative",top: "50%", transform:"translateY(15%)"}}
      >
        <FormLabel>
          <Text
            as="h1"
            fontSize='1.7rem'
            fontWeight="500"
            fontFamily="Roboto"
          >
            Olá colaborador, você irá compartilhar uma ideia individualmente ou
            em grupo?
          </Text>
        </FormLabel>
        <RadioGroup
          mt={{ base: 3, md: 6, lg: 8 }}
          colorScheme="green"
          size="md"
          onChange={setValue}
          value={value}
        >
          <Stack
            spacing={{ base: 4, lg: 6 }}
            direction={{ base: 'column', md: 'row', lg: 'row' }}
            mb={{ base: 32, lg: 32 }}
          >
            <Radio
              size="md"
              value="alone"
              onClick={() => setValue('alone')}
              data-cy="alone-selector"
            >
              <Text
                fontSize='1.6rem'
                data-cy="alone-text-selector"
              >
                Individualmente
              </Text>
            </Radio>
            <Radio
              value="group"
              onClick={() => setValue('group')}
              data-cy="group-selector"
            >
              <Text
                fontSize='1.6rem'
                data-cy="group-text-selector"
              >
                Em grupo
              </Text>
            </Radio>
          </Stack>
        </RadioGroup>
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          maxW="100%"
          mt={{ base: '4rem', lg: '6.25rem' }}
          spacing="1.5rem"
        >
          <FormButton
            data-cy="return"
            onClick={() => navigate('/form/1', { replace: true })}
          >
            VOLTAR
          </FormButton>
          <FormButton
            data-cy="submit"
            primary
            onClick={() => handleNext(value)}
          >
            AVANÇAR
          </FormButton>
        </Stack>
      </Box>
    </FormOuterContainer>
  );
};
