import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ChakraProvider } from '@chakra-ui/react';

import { App } from './App';
import { msalConfig } from './config/msal.config';
import { theme } from './global/styles/theme';
import { store } from './store';

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <BrowserRouter>
    <ChakraProvider theme={theme}>
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <App />
        </Provider>
      </MsalProvider>
    </ChakraProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);
