import { Configuration } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_APP_CLIENT_ID!,
    authority: process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_APP_AUTHORITY,
    redirectUri: process.env.REACT_APP_BASE_URL,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};
